<template>
  <div v-if="currentUser.superAdmin">
    <v-data-table
      :headers="headers"
      :items="globalGoals"
      :options.sync="pagination"
      :server-items-length="totalGlobalGoals"
      :loading="loading"
      :footer-props="{
        'showFirstLastPage':true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
      class="elevation-1"
    >

      <template v-slot:item.clear="{ item }">
        <v-avatar color="teal" size="26" rounded>
          <router-link
            :to="{
              name: 'GlobalGoalEdit',
              params: { globalGoalId: item.id }
            }"
          >
            <v-icon dark>mdi-pencil</v-icon>
          </router-link>
        </v-avatar>
      </template>
      <template v-slot:item.add="{ item }">
        <v-avatar color="red darken-4" size="26" rounded>
          <v-icon dark @click="delRecord(item)">mdi-delete</v-icon>
        </v-avatar>
      </template>
      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-avatar color="indigo" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  retrieveGlobalGoals();
                "
                >mdi-magnify
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <router-link :to="{ name: 'GlobalGoalCreate' }">
              <v-avatar color="teal" size="26" rounded>
                <v-icon dark>mdi-plus</v-icon>
              </v-avatar>
            </router-link>
          </td>
        </tr>
      </template>
    </v-data-table>
    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ConfirmDialog from "@/view/pages/ConfirmDialog";
import {mapGetters} from "vuex";

export default {
  name: "globalGoals",
  computed: {
    ...mapGetters(["currentUser"]),
    name(){return this.$i18n.t('menu.globalGoals')},
    headers() {
      return [
        { text : "Id" , align : "start" , sortable : true , value : "id" } ,
        { text : this.$i18n.t('common.title'), value : "title" } ,
        { text : this.$i18n.t('common.description'), value : "description" } ,
        { text : "" , value : "search" , width : "5%" } ,
        { text : "" , value : "clear" , width : "5%" } ,
        { text : "" , value : "add" , width : "5%" }
      ]
    }
  },
  data() {
    return {
      totalGlobalGoals: 0,
      globalGoals: [],
      globalGoal: {
        id: null,
        title: "",
        description: ""
      },
      loading: true,
      pagination: {},
      dialogDelete: false,
      itemToDelete: null,
      errors: []
    };
  },
  watch: {
    pagination: {
      handler() {
        this.retrieveGlobalGoals();
      },
      deep: true
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title : this.name }]);
  },
  components: {
    ConfirmDialog
  },
  methods: {
    async delRecord(item) {
      if (
        await this.$refs.confirm.open(
          this.$i18n.t('common.confirm'),
          this.$i18n.t('pages.shoppingEvents.globalGoalDeleteMessage') +
            item.id +
            " ?"
        )
      ) {
        this.deleteRecord(item);
      }
    },
    deleteRecord(item) {
      this.deleteGlobalGoal(item.id);
    },
    deleteItem(item) {
      this.itemToDelete = item;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.deleteGlobalGoal(this.itemToDelete.id);
      this.dialogDelete = false;
    },
    deleteGlobalGoal(globalGoalId) {
      this.loading = true;
      ApiService.delete(`api/global-goal/${globalGoalId}`)
        .then(response => {
          this.$log.debug("Global goal deleted: ", response);
          this.retrieveGlobalGoals();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    getRequestParams() {
      let params = {};

      let sort;
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sort = sortBy[0];
        if (sortDesc[0] === true) sort += ",desc";
      }
      params["sort"] = sort;
      params["page"] = this.pagination.page;
      params["size"] = this.pagination.itemsPerPage;

      return params;
    },

    retrieveGlobalGoals() {
      const params = this.getRequestParams();
      return new Promise(resolve => {
        this.loading = true;
        ApiService.query("api/global-goal", {
          params
        }).then(response => {
          // JSON responses are automatically parsed.
          this.$log.debug("Global goals: ", response.data.content);
          this.globalGoals = response.data.content;
          this.totalGlobalGoals = response.data.totalElements;
          this.totalPages = response.data.totalPages;
          this.loading = false;
          let title =
            this.totalGlobalGoals != null
              ? this.totalGlobalGoals
              : 0;

          this.$store.dispatch ( SET_BREADCRUMB , [{ title : `${this.name}: ` + title }] );
          resolve();
        });
      });
    }
  }
};
</script>

<style>
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 16px;
}
.v-icon.v-icon {
  font-size: 24px !important;
}
tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}
</style>
